/**
 * @description: 图表tooltip循环显⽰
 * @author: candy.d.chen
 * @LastEditors: candy.d.chen
 * @LastEditTime: 2021/6/25 10:39
 */
// 内容可以不需要修改
/**
 * maChart是echarts初始化的实例对象
 * num 是series ⾥⾯的data长度，传⼊值可为option.series[0].data.length
 * time 是时间间隔
 */

let timeTicket = null
export function autoPlay(myCharts, num, time) {
	// 设置默认值
	const defaultData = { // 
		time: 3000, //间隔时间
		num: 100 //循环次数---一般为x轴的坐标数
	};
	if (!time) {
		time = defaultData.time
	}
	if (!num) {
		num = defaultData.num
	}
	let count = 0
	timeTicket && clearInterval(timeTicket)
	timeTicket = setInterval(() => {
		myCharts.dispatchAction({
			type: 'downplay',
			// 的索引值可以触发多个
			seriesIndex: 0 // serieIndex
		})
		myCharts.dispatchAction({
			type: 'highlight',
			seriesIndex: 0,
			dataIndex: count
		})
		myCharts.dispatchAction({
			type: 'showTip',
			seriesIndex: 0,
			dataIndex: count
		})
		count++
		if (count >= num) {
			count = 0
		}
	}, time)
	myCharts.on('mouseover', (params) => {
		clearInterval(timeTicket)
		myCharts.dispatchAction({
			type: 'downplay',
			seriesIndex: 0
		})
		myCharts.dispatchAction({
			type: 'highlight',
			seriesIndex: 0,
			dataIndex: params.dataIndex
		})
		myCharts.dispatchAction({
			type: 'showTip',
			seriesIndex: 0,
			dataIndex: params.dataIndex
		})
	})
	myCharts.on('mouseout', () => {
		timeTicket && clearInterval(timeTicket)
		timeTicket = setInterval(() => {
			myCharts.dispatchAction({
				type: 'downplay',
				seriesIndex: 0 // serieIndex
			})
			myCharts.dispatchAction({
				type: 'highlight',
				seriesIndex: 0,
				dataIndex: count
			})
			myCharts.dispatchAction({
				type: 'showTip',
				seriesIndex: 0,
				dataIndex: count
			})
			count++;
			if (count >= num) {
				count = 0
			}
		}, time)
	})
	/* 注意使⽤
	或者
	框架时，页⾯重新渲染时要清除相应的定时器，避免页⾯错乱，所以这⾥把
	// 
	vue
	react 
	timeTicket 返回出去，以便销毁清除定时器 */
	return timeTicket
}
export default {
	timeTicket,
	autoPlay
}
