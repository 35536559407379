// 引入路由
// import VueRouter from 'vue-router'//vue2.0
import {createRouter,createWebHistory,createWebHashHistory} from 'vue-router'//vue3.0
// 引入组件
import HomeView from '../components/HomeView.vue'

// 创建一个路由器 vue3.0方式
const router =createRouter({
	history: createWebHashHistory(),
	routes:[{
		path:'/',
		name:'home',
		component:HomeView,
	    meta: {
			title: "首页",
			requireAuth: true // 标识该路由是否需要登录
		},
		// 进入路由之前  和全局前置路由守卫一样
		// beforeEnter:(to,from,next)=>{}
	},
	{
	      path: '/login',
	      name: 'login',
	      // route level code-splitting
	      // this generates a separate chunk (About.[hash].js) for this route
	      // which is lazy-loaded when the route is visited.
	      component: () => import('../components/LoginView.vue'),
		  meta: {
		  	title: "登录",
		  	requireAuth: false // 标识该路由是否需要登录
		  }
	    }]
})
// vue2创建路由的方式
// const router =new VueRouter({
// 	routes:[{
// 		path:'/',
// 		name:'home',
// 		component:HomeView
// 	}]
// })


// 全局前置路由守卫---初始化的时候被调用，每次路由切换之前被调用
/* 去哪 来自哪 要去哪 */
router.beforeEach((to, from, next) => {
    /**
     * 未登录则跳转到登录页
     * 未登录跳转到登录页,也可以通过axios的响应拦截器去实现,但是会先在当前页面渲染一下,再跳转到登录页,会有个闪动的现象
     * 这里通过路由守卫的方式,不会在当前页闪现一下,但是需要在每个路由组件添加一个是否需要登录的标识位,如本项目中的requireAuth字段
     */
    if (to.matched.some((auth) => auth.meta.requireAuth)) {
        let login = localStorage.getItem("login");
        if (login) {
            next();
        } else {
            next({
                path: "/login"
            });
        }
    } else {
        next();
    }
})

// 全局后置路由守卫---初始化的时候被调用，每次路由切换之后被调用
router.afterEach((to,from)=>{
	document.title = to.meta.title
})


// 独享路由守卫  只对一个路由做出限制

export default router