import http from '../utils/http'
// 接口
const login = (params) => http.post('login',params)//登录
const deviceType = (params) => http.post('deviceType',params) //设备分类、类型
const statisticalData = (params) => http.post('deviceUsage',params)//统计 故障前五  使用率前五  高峰时间折线图
const latestOrder = (params) => http.post('latestOrder',params)//最新的五个订单
const agentShop = (params) => http.post('agentShop',params)//最新的五个订单
const shopList  = (params) => http.post('shopList',params)//店铺列表
export default {
	login,
	deviceType,
	statisticalData,
	latestOrder,
	agentShop,
	shopList
}