import axios from 'axios'
import CONFIG from '../../public/serverConfig.js'

// console.log(CONFIG)
const baseRequest=axios.create({
	baseURL:CONFIG.base_URL,
	timeout:3000
})

// 添加请求拦截器
baseRequest.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  config.headers['content-Type'] = 'multipart/form-data';
  // config.data = JSON.stringify(config.data); //数据转化
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
 
// 添加响应拦截器
baseRequest.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
	// console.log(response)
	// if(response.data.code==200){}
  return response;
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
   /***** 接收到异常响应的处理开始 *****/
  //   if (error && error.response) {
  //     // 1.公共错误处理
  //     // 2.根据响应码具体处理
  //     switch (error.response.status) {
  //       case 400:
  //         error.message = '错误请求'
  //         break;
  //       case 401:
  //         error.message = '未授权，请重新登录'
  //         break;
  //       case 403:
  //         error.message = '拒绝访问'
  //         break;
  //       case 404:
  //         error.message = '请求错误,未找到该资源'
  //         window.location.href = "/NotFound"
  //         break;
  //       case 405:
  //         error.message = '请求方法未允许'
  //         break;
  //       case 408:
  //         error.message = '请求超时'
  //         break;
  //       case 500:
  //         error.message = '服务器端出错'
  //         break;
  //       case 501:
  //         error.message = '网络未实现'
  //         break;
  //       case 502:
  //         error.message = '网络错误'
  //         break;
  //       case 503:
  //         error.message = '服务不可用'
  //         break;
  //       case 504:
  //         error.message = '网络超时'
  //         break;
  //       case 505:
  //         error.message = 'http版本不支持该请求'
  //         break;
  //       default:
  //         error.message = `连接错误${error.response.status}`
  //     }
  //   } else {
  //     // 超时处理
  //     if (JSON.stringify(error).includes('timeout')) {
  //       Message.error('服务器响应超时，请刷新当前页')
  //     }
  //     error.message = '连接服务器失败'
  //   }
  
  //   Message.error(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
	// return Promise.reject(error);
});



// const request = {};

// ['options', 'get', 'post', 'put', 'head', 'delete', 'trace', 'connect'].forEach((method) => {
// 	request[method] = (api, data, opt) => baseRequest(api, method, data, opt || {})
// });



export default baseRequest;