import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
import router from './router'
// Vue.use(VueRouter)
import api from './api'

// Vue.prototype.$http=api

const app = createApp(App)
app.use(router)
app.mount('#app')
app.config.globalProperties.$http=api


// https://console-docs.apipost.cn/preview/b82f5fc14dfa08a7/3b9f47355418e039