<template>
  <router-view :key="keyname"></router-view>
</template>

<script>
export default {
  name: 'App', 
	data(){
		return {
			keyname:''
		}
	},
	mounted() {
		this.keyname=this.$route.name
		// console.log(this.$route.name)
	}
}
</script>

<style>

</style>
